import AppStateService from '@ajs/services/AppStateService';
import FdxUI from '@ajs/services/fdxUI';
import { HttpErrorResponse } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, UntypedFormBuilder } from '@angular/forms';
import { isEmpty } from '@app/core/functions/isEmpty';
import { FdxUtilsService } from '@app/core/services/fdx-utils.service';
import { BaseExportFormComponent } from '@app/exports/components/base-export-form/base-export-form.component';
import { DesignatedHostsModalComponent } from '@app/exports/components/designated-hosts-modal/designated-hosts-modal.component';
import { EXPORT_DESTINATION_DROPDOWN_VALUES } from '@app/exports/constants/export-destinations.constants';
import { HOSTS } from '@app/exports/constants/url.constants';
import { WebhookType } from '@app/exports/data/webhook-type';
import { ExportProtocol } from '@app/exports/enums/export.enums';
import { ExistingExport } from '@app/exports/models/interfaces/existing-export.interface';
import { ExportDestinationDropdownItem } from '@app/exports/models/interfaces/export-destination-dropdown-item.interface';
import { WebhookParamsInterface } from '@app/exports/models/interfaces/webhook-params.interface';
import { WebhookTypeOptionInterface } from '@app/exports/models/interfaces/webhook-type-option.interface';
import { Export } from '@app/exports/services/responses/get-exports.response';
import { WebhookTypesService } from '@app/exports/services/webhook-types.service';
import { FtpAccountDataService, TestFtpParams } from '@app/ftp-account/services/ftp-account-data.service';
import { GetFtpAccountsResponse } from '@app/ftp-account/services/responses/get-ftp-accounts.response';
import { ModalService } from '@app/modules/modals/services/modal.service';
import { NgSelectComponent } from '@feedonomics/ng-select';
import { IconDefinition, faCopy, faQuestionCircle } from '@fortawesome/pro-solid-svg-icons';
import { takeUntil, tap } from 'rxjs';

enum FtpLoginType {
    Normal= 'normal',
    Alternate= 'seclib',
    PrivateKey = 'private_key'
}

@Component({
    selector: 'fdx-credentials-form',
    styleUrls: ['./export-credentials-form.component.scss'],
    templateUrl: './export-credentials-form.component.html'
})
export class ExportCredentialsFormComponent extends BaseExportFormComponent implements OnChanges, OnInit {
    @Input() headerLabel: string = 'Credentials';
    @Input() showFtpUrl: boolean = false;
    @Input() idPrepend: string = '';
    @Output() readonly webhookTypeChange: EventEmitter<WebhookType> = new EventEmitter<WebhookType>();

    @ViewChild('destinationSelect') destinationSelect: NgSelectComponent;

    fileNamePlaceholder: string;
    readonly ftpLoginTypes: { display:string; value: FtpLoginType; }[] = [
        {
            display: 'Normal (default)',
            value: FtpLoginType.Normal
        },
        {
            display: 'Normal (alternate)',
            value: FtpLoginType.Alternate
        },
        {
            display: 'Private Key Auth(RSA)',
            value: FtpLoginType.PrivateKey
        }
    ];
    readonly iconCopy: IconDefinition = faCopy;
    readonly iconHelp: IconDefinition = faQuestionCircle;
    webhookTypeOptions: WebhookTypeOptionInterface[] = [];
    webhookTypeParams: Record<string, { params: WebhookParamsInterface }> = {};
    ftpAccount: GetFtpAccountsResponse;
    accountFeatures: Record<string, string> = {};
    sftpAccountDefaultActive: boolean = false;

    readonly exportProtocol: typeof ExportProtocol = ExportProtocol;
    exportDestinationDropdownList: ExportDestinationDropdownItem[] = EXPORT_DESTINATION_DROPDOWN_VALUES;
    searchedText: string = null;

    // https://feedonomics.atlassian.net/browse/FP-9862
    // popoverFtpDeprecationMessage: NgbPopover | null = null;

    // eslint-disable-next-line accessor-pairs
    // @ViewChild('popoverFtpDeprecationMessage') set content(content: NgbPopover) {
    //     this.popoverFtpDeprecationMessage = content;
    // }

    // eslint-disable-next-line @typescript-eslint/typedef
    destinationForm = new FormGroup({
        destination: new FormControl<string>(null),
        customDestination: new FormControl<string>(null)
    })

    get compressionControl(): AbstractControl {
        return this.form?.controls.compression;
    }

    get databaseId(): string {
        return this.appStateService.getDatabaseId();
    }

    get ftpButtonLabel(): string {
        return (this.useSftp ? ExportProtocol.SFTP : ExportProtocol.FTP).toUpperCase();
    }

    get ftpLoginType(): FtpLoginType {
        return this.form?.controls.protocolInfo.value.loginType;
    }

    get ftpUrl(): string {
        return this.buildFtpUrl(this.exportItem);
    }

    get httpUrl(): string {
        return this.buildHttpUrl(this.exportItem);
    }

    get protocolControl(): AbstractControl {
        return this.form?.controls.protocol;
    }

    get showPrivateKeyInputs(): boolean {
        return this.useSftp && this.ftpLoginType === FtpLoginType.PrivateKey;
    }

    get useZip(): boolean {
        return this.compressionControl?.value === 'zip';
    }

    get useSftp(): boolean {
        return this.protocolControl.value === ExportProtocol.SFTP;
    }

    get useWebhook(): boolean {
        return this.protocolControl.value === ExportProtocol.Webhook;
    }

    get showFtpPrivacy(): boolean {
        // exportItem.host may have port info appended, remove port before comparing to ftpAccount.host
        const exprtHost: string = this.removePortFromHost(this.exportItem.host);

        return (this.ftpAccount
            && this.exportItem.username === this.ftpAccount.username
            && this.exportItem.password === this.ftpAccount.password
            && exprtHost.startsWith(this.ftpAccount.host))
    }

    get ftpPrivacyNote(): string {
        return `Files are: ${ this.ftpAccount.public === '1' ? 'Public' : 'Private' }`;
    }

    get showHttpUrl(): boolean {
        return this.showFtpPrivacy && this.ftpAccount.public === '1';
    }

    get showCustomPlatformField(): boolean {
        return this.destinationForm.controls.destination.value === 'Other';
    }

    constructor(
        private readonly appStateService: AppStateService,
        private readonly fdxUI: FdxUI,
        private readonly fdxUtils: FdxUtilsService,
        private readonly fb: UntypedFormBuilder,
        private readonly ftpAccountDataService: FtpAccountDataService,
        private readonly modalService: ModalService,
        private readonly webHookTypesService: WebhookTypesService
    ) {
        super();
    }

    ngOnInit(): void {
        super.ngOnInit();

        this.initFileNamePlaceholder();
        this.initWebhookTypeOptions();
        this.initWebhookTypeParams();

        if (this.showFtpUrl) {
            this.fetchFtp();
        }

        this.accountFeatures = this.appStateService.getAccount()?.features;
        if ('sftp_account_default' in this.accountFeatures) {
            this.sftpAccountDefaultActive = true;
        }

        // https://feedonomics.atlassian.net/browse/FP-9862
        // this.form.valueChanges
        //     .pipe(takeUntil(this.unsubscribe$))
        //     .subscribe((values) => {
        //         if (ILLEGAL_FTP_URLS.has(values.host)) {
        //             this.popoverFtpDeprecationMessage?.open();
        //         } else {
        //             this.popoverFtpDeprecationMessage?.close();
        //         }
        //     });

        // this.updateDestinationListeners();
    }

    private initWebhookTypeOptions(): void {

        this.webHookTypesService.getWebhookTypes()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((data) => {
                this.webhookTypeOptions = data.map((entry) => {
                    return {
                        value: entry.webhook_type,
                        display_name: entry.display_name,
                        group: entry.group
                    }
                });
            });

    }

    private initWebhookTypeParams(): void {

        this.webHookTypesService.getWebhookTypes()
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((data) => {

                const dataParams = {};

                data.forEach((entry) => {
                    dataParams[entry.webhook_type] = {
                        params: JSON.parse(entry.params) as WebhookParamsInterface
                    }
                });

                this.webhookTypeParams = dataParams;

            });

    }

    buildFtpUrl(exportItem: Export): string {
        if (!exportItem) {
            return '';
        }

        let ftpUrl: string;

        if (!['ftp', 'sftp'].includes(exportItem.protocol)) {
            ftpUrl = '';
        }

        const ftpParams = {
            protocol: exportItem.protocol,
            username: exportItem.username,
            password: exportItem.password,
            host: exportItem.host,
            filename: exportItem.file_name
        };

        ftpUrl = this.getFtpUrl(ftpParams);

        return ftpUrl;
    }

    copyText(text: string): void {
        this.fdxUtils.copyTextToClipboard(text)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe({
                complete: () => {
                    this.fdxUI.showToastSuccess('Copied to clipboard');
                },
                error: () => {
                    this.fdxUI.showToastError('Failed to copy to clipboard');
                }
            });
    }

    initFileNamePlaceholder(): void {
        this.fileNamePlaceholder = this.makeFileNamePlaceholder(this.compressionControl.value);

        this.compressionControl.valueChanges
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((compression) => {
                this.fileNamePlaceholder = this.makeFileNamePlaceholder(compression);
            });
    }

    protected initForm(): void {
        this.form = this.fb.group({
            name: [this.exportItem?.name ?? null],
            fileName: [this.exportItem?.file_name ?? null],
            compression: [this.exportItem?.compression ?? 'gzip'],
            zipInnerFileName: [this.exportItem?.zip_inner_file_name ?? null],
            protocol: [this.exportItem?.protocol ?? ExportProtocol.FTP],
            webhookType: [this.exportItem?.webhook_type ?? null],
            host: [this.exportItem?.host ?? null],
            username: [this.exportItem?.username ?? null],
            password: [this.exportItem?.password ?? null],
            destination: [this.exportItem?.destination ?? null],
            protocolInfo: this.fb.group({
                httpMethod: [this.exportItem?.protocol_info?.http_method ?? null],
                httpUrl: [this.exportItem?.protocol_info?.http_url ?? null],
                httpHeaders: [this.exportItem?.protocol_info?.http_headers ?? null],
                httpBody: [this.exportItem?.protocol_info?.http_body ?? null],
                loginType: [this.exportItem?.protocol_info?.login_type ?? null],
                privateKey: [this.exportItem?.protocol_info?.private_key ?? null],
                privateKeyPass: [this.exportItem?.protocol_info?.private_key_pass ?? null]
            })
        });
    }

    makeFileNamePlaceholder(compression: string): string {
        const placeholder = 'feed';
        let fileExtension = 'txt';

        if (compression === 'zip') {
            fileExtension = 'zip';
        } else if (compression === 'gzip') {
            fileExtension = 'txt.gz';
        }

        return `${placeholder}.${fileExtension}`;
    }

    makeTestFtpParams(): TestFtpParams {
        const ftpData: TestFtpParams = {
            host: this.form.controls.host.value,
            username: this.form.controls.username.value,
            password: this.form.controls.password.value,
            protocol: this.form.controls.protocol.value
        };

        if (this.useSftp) {
            ftpData.protocol_info = {
                login_type: this.form.controls.protocolInfo.value.loginType,
                private_key: this.form.controls.protocolInfo.value.privateKey,
                private_key_pass: this.form.controls.protocolInfo.value.privateKeyPass
            };
        }

        return ftpData;
    }

    protected patchForm(exportItem: ExistingExport): void {
        this.patchValue({
            name: exportItem?.name ?? null,
            fileName: exportItem?.file_name ?? null,
            compression: exportItem?.compression ?? 'gzip',
            zipInnerFileName: exportItem?.zip_inner_file_name ?? null,
            protocol: exportItem?.protocol ?? ExportProtocol.FTP,
            webhookType: exportItem?.webhook_type ?? null,
            host: exportItem?.host ?? null,
            username: exportItem?.username ?? null,
            password: exportItem?.password ?? null,
            destination: exportItem?.destination ?? null,
            protocolInfo: {
                httpMethod: exportItem?.protocol_info?.http_method ?? null,
                httpUrl: exportItem?.protocol_info?.http_url ?? null,
                httpHeaders: exportItem?.protocol_info?.http_headers ?? null,
                httpBody: exportItem?.protocol_info?.http_body ?? null,
                loginType: exportItem?.protocol_info?.login_type ?? null,
                privateKey: exportItem?.protocol_info?.private_key ?? null,
                privateKeyPass: exportItem?.protocol_info?.private_key_pass ?? null
            }
        });

        this.initializeDestinationForm(this.exportItem.destination ?? null);
    }

    onWebhookTypeChange({ value }: { value: WebhookType }): void {
        const params = this.webhookTypeParams[value].params;
        const patchedValues = {};

        Object.entries(this.form.controls).forEach(([key, control]) => {
            const snakeKey = this.fdxUtils.camelToSnakeCase(key);

            if (snakeKey in params) {
                if (typeof control.value === 'object' && !isEmpty(control.value)) {
                    patchedValues[key] = this.fdxUtils.snakeToCamelCaseObject(params[snakeKey]);
                } else {
                    patchedValues[key] = params[snakeKey];
                }
            }
        });

        this.form.patchValue(patchedValues);
        this.webhookTypeChange.emit(value);
    }

    openIpWhitelistingModal(url: string): void {
        this.modalService.open(DesignatedHostsModalComponent, {
            resolve: {
                databaseId: this.databaseId,
                url
            }
        });
    }

    fetchFtp(populate: boolean = false, useSftp: boolean = false): void {
        this.ftpAccountDataService.getFtpAccounts(this.databaseId, true)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe((response) => {
                if (this.sftpAccountDefaultActive === true && 'account_default' in response) {
                    this.ftpAccount = response.account_default;
                } else {
                    this.ftpAccount = response;
                }


                if (populate) {
                    this.populateFtp(useSftp);
                }
            });
    }

    populateFtp(useSftp: boolean): void {
        if (this.ftpAccount instanceof Array) {
            this.fdxUI.showToastError('You haven\'t created an FTP yet.');
            return;
        }

        const host = useSftp ? `${this.ftpAccount.host}:${this.ftpAccount.port}` : this.ftpAccount.host;

        this.form.patchValue({
            host: host,
            username: this.ftpAccount.username,
            password: this.ftpAccount.password
        });
    }

    buildHttpUrl (exportItem: Export): string {
        if (!exportItem) {
            return '';
        }
        let fileName = '';
        if (exportItem.file_name) {
            fileName = this.removeFolderFromFileName(exportItem.file_name);
            if (!fileName?.startsWith('/')) {
                fileName = '/' + fileName;
            }
        }
        // public access url doesn't work if port is present
        let exprtHost: string = this.removePortFromHost(exportItem.host);
        exprtHost = this.removeFolderFromHost(exprtHost);
        return `https://${exprtHost}/ftp/${exportItem.username}${fileName}`;
    }

    removePortFromHost(hostValue: string): string {
        const colonPos: number = hostValue.indexOf(':');
        if (colonPos !== -1) {
            return hostValue.slice(0, colonPos);
        }
        return hostValue;
    }

    /**
     * Removes special case folders from the host name, like incoming so the download link
     * does not include them.
     * @param {string} hostValue The name of the host
     * @return {string} The host value without specific folders attached.
     */
    removeFolderFromHost(hostValue: string): string {
        return hostValue.replace(new RegExp('/incoming(?:/$)?'), '');
    }

    /**
     * Removes special case folders from the file name, like incoming so the download link
     * does not include them.
     * @param {string} fileName The path of the file
     * @return {string} The file path without specific folders attached.
     */
    removeFolderFromFileName(fileName: string): string {
        return fileName.replace(new RegExp('^/?incoming'), '');
    }

    testFtp(): void {
        const ftpData = this.makeTestFtpParams();

        this.ftpAccountDataService.testFtp(this.databaseId, ftpData)
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(
                () => {
                    this.fdxUI.showToastSuccess('The FTP connection was successful!');
                },
                (error: HttpErrorResponse) => {
                    if (error.error?.short_message) {
                        this.fdxUI.showToastError(error.error.short_message);
                    }

                    if (error.error?.action_message) {
                        this.fdxUI.showToastError(error.error.action_message);
                    }
                }
            );
    }

    updateExportProtocol(protocol: string): void {
        if (protocol !== ExportProtocol.Webhook && protocol !== ExportProtocol.SFTP) {
            return;
        }

        if (protocol === ExportProtocol.Webhook) {
            this.form.patchValue({
                protocolInfo: {
                    httpMethod: '',
                    httpUrl: '',
                    httpHeaders: '',
                    httpBody: ''
                }
            });
        } else if (protocol === ExportProtocol.SFTP) {
            this.form.patchValue({
                protocolInfo: {
                    loginType: FtpLoginType.Normal,
                    privateKey: '',
                    privateKeyPass: ''
                }
            });
        }
    }

    private getFtpUrl(ftpParams:
                  {
                      protocol: string;
                      username: string;
                      password: string;
                      host: string;
                      filename: string;
                  }
    ): string {

        const validateParams = ftpParams.protocol
            && ftpParams.username
            && ftpParams.password
            && ftpParams.host;

        if (validateParams) {

            const host = ftpParams.host;

            let ftpUrl = `${ftpParams.protocol}://${ftpParams.username}:${ftpParams.password}@${host}`;

            if (ftpParams.filename) {

                let fileName: string;

                if(  !HOSTS.includes(host) ) {
                    // specifically remove the /incoming folder as that shouldn't be part of output urls
                    fileName = ftpParams.filename.replace(/^\/?incoming(\/.+)/, '$1');
                }else {
                    // FP-10474
                    fileName = '/incoming/' + ftpParams.filename;
                }

                if (!fileName || fileName.startsWith('/')) {
                    ftpUrl += fileName;
                } else {
                    ftpUrl += `/${fileName}`;
                }
            }

            return ftpUrl;

        }

        return '';

    }

    private initializeDestinationForm(destination: string): void {
        if (!destination) { 
            this.destinationForm.patchValue({
                destination: null,
                customDestination: null
            });
            return;
        }

        const isStandardDestination = this.exportDestinationDropdownList.some((dropdownListItem) => {
            return dropdownListItem.value === destination;
        });

        if (isStandardDestination) {
            this.destinationForm.patchValue({
                destination,
                customDestination: null
            });
        } else {
            this.destinationForm.patchValue({
                destination: 'Other',
                customDestination: destination
            });

        }
    }

    private updateDestinationListeners(): void {
        this.destinationForm.controls.destination.valueChanges.pipe(
            tap((destination: string) => {
                if (destination === 'Other') {
                    this.form.patchValue({
                        destination: null
                    }, { emitEvent: false });
                } else if (destination !== this.form.controls.destination.value) {
                    this.form.patchValue({
                        destination
                    });

                    this.destinationForm.controls.customDestination.patchValue(null);
                }
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe();

        this.destinationForm.controls.customDestination.valueChanges.pipe(
            tap((destination: string) => {
                if (destination === null || destination === this.form.controls.destination.value) {
                    return;
                }
                this.form.patchValue({
                    destination
                });
            }),
            takeUntil(this.unsubscribe$)
        ).subscribe();
    }

    searchTextChanged(event: { term: string, items: unknown[] }): void {
        this.searchedText = '';
        if (event.items.length === 0) {
            this.searchedText = event.term;
        }
    }

    enterPressed(): void {
        if (this.searchedText.length > 0) {
            this.destinationForm.controls.destination.setValue('Other');
            this.destinationForm.controls.customDestination.setValue(this.searchedText);
            this.destinationSelect.close();
        }
    }

}
