<div [formGroup]="form">
    <fdx-form-section [header]="headerLabel">
        <ng-container>
            <div class="row">
                <label
                    [for]="idPrepend + 'name'"
                    class="col-sm-4 col-form-label text-end">
                    Export name
                </label>
                <div class="col-sm-8">
                    <input
                        [id]="idPrepend + 'name'"
                        type="text"
                        class="form-control"
                        placeholder="Google Shopping Export"
                        formControlName="name">
                </div>
            </div>

            <!-- <ng-container [formGroup]="destinationForm">
                <fdx-form-field
                    [classes]="{'row align-items-center mt-3': true}"
                    formControlName="destination">
                    <fdx-label class="col-4 text-end">Destination</fdx-label>
                    <fdx-input-wrapper class="col-8">
                        <ng-select #destinationSelect
                            [items]="exportDestinationDropdownList"
                            id="destination"
                            formControlName="destination"
                            groupBy="section"
                            bindLabel="value" bindValue="value"
                            (search)="searchTextChanged($event)"
                            (keydown.enter)="enterPressed()">
                            <ng-template ng-optgroup-tmp let-item="item" let-index="index">
                            </ng-template>
                            <ng-template ng-notfound-tmp let-searchTerm="searchTerm">
                                <div class="ng-option disabled">
                                    No data found
                                </div>
                                <div class="ng-option ng-option-marked" (click)="enterPressed()" tabindex="0" >Use "{{searchTerm}}" <code>Enter</code></div>
                            </ng-template>
                        </ng-select>
                    </fdx-input-wrapper>

                </fdx-form-field>

                <fdx-form-field
                    [classes]="{'row align-items-center mt-3': true}"
                    formControlName="customDestination"
                    *ngIf="showCustomPlatformField">
                    <fdx-input class="col-8 offset-4"></fdx-input>
                </fdx-form-field>
            </ng-container> -->
            

            <div class="row mt-3">
                <label
                    [for]="idPrepend + 'fileName'"
                    class="col-sm-4 col-form-label text-end">
                    File name
                </label>
                <div class="col-sm-8">
                    <input
                        [id]="idPrepend + 'fileName'"
                        type="text"
                        class="form-control"
                        [placeholder]="fileNamePlaceholder"
                        formControlName="fileName">
                </div>
            </div>

            <div class="row mt-3">
                <label
                    [for]="idPrepend + 'compression'"
                    class="col-sm-4 col-form-label text-end">
                    Compression
                </label>
                <div class="col-sm-8">
                    <ng-select
                        [id]="idPrepend + 'compression'"
                        [clearable]="false"
                        formControlName="compression">
                        <ng-option value>None</ng-option>
                        <ng-option value="zip">Zip</ng-option>
                        <ng-option value="gzip">GZip</ng-option>
                    </ng-select>
                </div>
            </div>

            <div
                class="row mt-3"
                *ngIf="useZip">
                <label
                    [for]="idPrepend + 'zipInnerFileName'"
                    class="col-sm-4 col-form-label text-end">
                    Zip - Inner file name
                </label>
                <div class="col-sm-8">
                    <input
                        [id]="idPrepend + 'zipInnerFileName'"
                        type="text"
                        class="form-control"
                        placeholder="feed.txt"
                        formControlName="zipInnerFileName">
                </div>
            </div>

            <div class="row mt-5">
                <label
                    [for]="idPrepend + 'protocol'"
                    class="col-sm-4 col-form-label text-end">
                    Export protocol
                </label>
                <div class="col-sm-8">
                    <ng-select
                        [id]="idPrepend + 'protocol'"
                        [clearable]="false"
                        (change)="updateExportProtocol($event)"
                        formControlName="protocol">
                        <ng-option [value]="exportProtocol.FTP">FTP</ng-option>
                        <ng-option [value]="exportProtocol.SFTP">SFTP</ng-option>
                        <ng-option [value]="exportProtocol.FTPS">FTPS</ng-option>
                        <ng-option [value]="exportProtocol.Webhook">Webhook</ng-option>
                    </ng-select>
                </div>
            </div>

            <ng-container *ngIf="useWebhook">
                <div class="row mt-3">
                    <label
                        [for]="idPrepend + 'webhookType'"
                        class="col-sm-4 col-form-label text-end">
                        Webhook type
                    </label>
                    <div class="col-sm-8">
                        <ng-select
                            [id]="idPrepend + 'webhookType'"
                            [clearable]="false"
                            [items]="webhookTypeOptions"
                            (change)="onWebhookTypeChange($event)"
                            bindLabel="display_name"
                            bindValue="value"
                            groupBy="group"
                            formControlName="webhookType"
                            placeholder="Choose a Webhook Type">
                        </ng-select>
                    </div>
                </div>

                <ng-container formGroupName="protocolInfo">
                    <div class="row mt-3">
                        <label
                            [for]="idPrepend + 'httpMethod'"
                            class="col-sm-4 col-form-label text-end">
                            HTTP method
                        </label>
                        <div class="col-sm-8">
                            <input
                                [id]="idPrepend + 'httpMethod'"
                                type="text"
                                class="form-control"
                                placeholder="POST"
                                formControlName="httpMethod">
                        </div>
                    </div>

                    <div class="row mt-3">
                        <label
                            [for]="idPrepend + 'httpUrl'"
                            class="col-sm-4 col-form-label text-end">
                            URL
                        </label>
                        <div class="col-sm-8">
                            <input
                                #httpUrl
                                [id]="idPrepend + 'httpUrl'"
                                type="text"
                                class="form-control"
                                placeholder="https://webhook.com/process.php"
                                formControlName="httpUrl">

                            <button
                                (click)="openIpWhitelistingModal(httpUrl.value)"
                                name="ip-whitelisting"
                                type="button"
                                class="btn btn-link fs-6 px-0">
                                Need more information about IP Whitelisting?
                            </button>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <label
                            [for]="idPrepend + 'httpHeaders'"
                            class="col-sm-4 col-form-label text-end">
                            HTTP headers
                        </label>
                        <div class="col-sm-8">
                            <textarea
                                [id]="idPrepend + 'httpHeaders'"
                                class="form-control"
                                placeholder="Authorization: abc"
                                formControlName="httpHeaders">
                            </textarea>
                        </div>
                    </div>

                    <div class="row mt-3">
                        <label
                            [for]="idPrepend + 'httpBody'"
                            class="col-sm-4 col-form-label text-end">
                            HTTP body
                        </label>
                        <div class="col-sm-8">
                            <textarea
                                [id]="idPrepend + 'httpBody'"
                                class="form-control"
                                placeholder="HTTP Request Body"
                                rows="10"
                                formControlName="httpBody">
                            </textarea>
                        </div>
                    </div>
                </ng-container>
            </ng-container>

            <ng-container *ngIf="!useWebhook">
                <div class="row mt-3">
                    <div class="col-sm-8 offset-sm-4">
                        <button
                            (click)="fetchFtp(true, useSftp)"
                            name="use-feedonomics-ftp"
                            type="button"
                            class="btn btn-outline-primary">
                            Use my Feedonomics {{ftpButtonLabel}}
                        </button>
                    </div>
                </div>

                <div class="row mt-3">
                    <!-- https://feedonomics.atlassian.net/browse/FP-9862 -->
                    <!-- [autoClose]="false" [ngbPopover]="ftpMessage" triggers="manual" popoverClass="ftp-deprecation-message" #popoverFtpDeprecationMessage="ngbPopover"> -->
                    <label
                        [for]="idPrepend + 'host'"
                        class="col-sm-4 col-form-label text-end">
                        Host
                    </label>
                    <div class="col-sm-8">
                        <input
                            [id]="idPrepend + 'host'"
                            type="text"
                            class="form-control"
                            placeholder="uploads.google.com"
                            formControlName="host">
                    </div>
                </div>

                <div class="row mt-3">
                    <label
                        [for]="idPrepend + 'username'"
                        class="col-sm-4 col-form-label text-end">
                        Username
                    </label>
                    <div class="col-sm-8">
                        <input
                            [id]="idPrepend + 'username'"
                            type="text"
                            class="form-control"
                            placeholder="Username"
                            formControlName="username"
                            data-lpignore="true"
                            data-1p-ignore="true"
                            autocomplete="off">
                    </div>
                </div>

                <fieldset
                    [disabled]="showPrivateKeyInputs"
                    class="row mt-3">
                    <label
                        [for]="idPrepend + 'password'"
                        class="col-sm-4 col-form-label text-end">
                        Password
                    </label>
                    <div class="col-sm-8">
                        <input
                            [id]="idPrepend + 'password'"
                            type="text"
                            class="form-control"
                            placeholder="Password"
                            formControlName="password"
                            data-lpignore="true"
                            data-1p-ignore="true"
                            autocomplete="off">
                    </div>
                </fieldset>

                <ng-container
                    *ngIf="useSftp"
                    formGroupName="protocolInfo">
                    <div class="row mt-3">
                        <label
                            [for]="idPrepend + 'loginType'"
                            class="col-sm-4 col-form-label text-end">
                            Login type
                        </label>
                        <div class="col-sm-8">
                            <ng-select
                                [id]="idPrepend + 'loginType'"
                                [items]="ftpLoginTypes"
                                bindLabel="display"
                                bindValue="value"
                                [clearable]="false"
                                formControlName="loginType"
                                placeholder="Choose Login Type">
                            </ng-select>
                        </div>
                    </div>

                    <ng-container *ngIf="showPrivateKeyInputs">
                        <div class="row mt-3">
                            <label
                                [for]="idPrepend + 'privateKey'"
                                class="col-sm-4 col-form-label text-end">
                                Private key
                            </label>
                            <div class="col-sm-8">
                                <textarea
                                    [id]="idPrepend + 'privateKey'"
                                    class="form-control"
                                    placeholder="(complete rsa private key)"
                                    formControlName="privateKey">
                                </textarea>
                            </div>
                        </div>

                        <div class="row mt-3">
                            <label
                                [for]="idPrepend + 'privateKeyPass'"
                                class="col-sm-4 col-form-label text-end">
                                Key passphrase
                                <fa-icon
                                    [icon]="iconHelp"
                                    ngbPopover="If your private key is locked with a passphrase, enter it here"
                                    triggers="mouseenter:mouseleave"
                                    popoverTitle="Private Key Passphrase">
                                </fa-icon>
                            </label>
                            <div class="col-sm-8">
                                <input
                                    [id]="idPrepend + 'privateKeyPass'"
                                    type="text"
                                    class="form-control"
                                    placeholder="private-key-passphrase (usually blank)"
                                    formControlName="privateKeyPass">
                            </div>
                        </div>
                    </ng-container>
                </ng-container>

                <div
                    *ngIf="showFtpUrl"
                    class="row mt-3 ">
                    <label
                        [for]="idPrepend + 'ftpUrl'"
                        class="col-sm-4 col-form-label text-end">
                        {{ftpButtonLabel}} URL
                    </label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input
                                [id]="idPrepend + 'ftpUrl'"
                                type="text"
                                class="form-control"
                                [placeholder]="ftpUrl"
                                disabled>

                            <button
                                (click)="copyText(ftpUrl)"
                                name="copy-ftp-url"
                                class="btn btn-primary"
                                type="button">
                                <fa-icon
                                    [icon]="iconCopy"
                                    class="no-margin"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>

                <div class="row mt-3">
                    <div class="col-sm-4 offset-sm-4 text-muted">
                        <span *ngIf="showFtpPrivacy">{{ ftpPrivacyNote }}</span>
                    </div>
                    <div class="col-sm-4 text-end">
                        <button
                            (click)="testFtp()"
                            type="button"
                            class="btn btn-link">
                            Test FTP
                        </button>
                    </div>
                </div>

                <div
                    *ngIf="showHttpUrl"
                    class="row mt-3 ">
                    <label
                        class="col-sm-4 col-form-label text-end"
                        [for]="idPrepend + 'https-url'">
                        HTTPS URL
                    </label>
                    <div class="col-sm-8">
                        <div class="input-group">
                            <input
                                type="text"
                                [id]="idPrepend + 'https-url'"
                                class="form-control"
                                [placeholder]="httpUrl"
                                disabled>
                            <button
                                (click)="copyText(httpUrl)"
                                class="btn btn-primary"
                                type="button">
                                <fa-icon
                                    [icon]="iconCopy"
                                    class="no-margin"></fa-icon>
                            </button>
                        </div>
                    </div>
                </div>
            </ng-container>
        </ng-container>
    </fdx-form-section>
</div>

<!-- https://feedonomics.atlassian.net/browse/FP-9862 -->
<!-- <ng-template #ftpMessage>
    <fdx-ftp-host-deprecation-popover [popover]="popoverFtpDeprecationMessage" ></fdx-ftp-host-deprecation-popover>
</ng-template> -->
